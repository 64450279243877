import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm a lead software engineer, currently working at `}<a parentName="p" {...{
        "href": "https://www.nice.org.uk/"
      }}>{`NICE`}</a>{` in Manchester and living in The Heatons, a suburb of Stockport.`}</p>
    <h2>{`Front-end, back-end`}</h2>
    <p>{`I've been focussing more on front-end at NICE recently, but originally I came from a .NET background. So I'm equally happy building accessible web applications as I am writing APIs and backends.`}</p>
    <p>{`Tech-wise, the `}<a parentName="p" {...{
        "href": "https://github.com/nice-digital/technology-radar/#readme"
      }}>{`NICE Technology Radar`}</a>{` gives a pretty good idea of the tech stack I've been working with over the past few years.`}</p>
    <h2>{`Work-life balance`}</h2>
    <p>{`I've got 2 young children and 2 cats, so life can pretty hectic! I do try the odd side-project or contribute to open-source where I can, but I also appreciate work-life balance and downtime.`}</p>
    <p>{`When I'm not coding my time is split between looking after the kids, watching rugby, baking or drinking good beer!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      