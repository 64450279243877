import React from "react";
import { Layout } from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";

import styles from "./Page.module.scss";

type PageTemplateProps = {
  pageContext: {
    frontmatter: {
      title: string;
      description: string;
    };
  };
  children: React.ReactNode;
};

const PageTemplate = ({
  children,
  pageContext: { frontmatter },
}: PageTemplateProps) => (
  <Layout>
    <Hero heading={frontmatter.title} lead={frontmatter.description}></Hero>
    <div className={styles.body}>{children}</div>
  </Layout>
);

export default PageTemplate;

// export const pageQuery = graphql`
//   query($slug: String!) {
//     mdx(fields: { slug: { eq: $slug } }) {
//       id
//       excerpt(pruneLength: 160)
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//       }
//       body
//     }
//   }
// `;
